
import { defineComponent, ref, computed } from 'vue'
import { escapeText } from '@/services/utils'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmSearch from '@/components/shared/TmSearch.vue'
import { additionalSettingsFromOptions } from '@/definitions/_general/_data/optionsList'

export default defineComponent({
  name: 'EditSenderModal',
  components: {
    TmButton,
    TmModal,
    TmSearch,
  },
  setup() {
    const select = ref(additionalSettingsFromOptions[0])
    const filterValue = ref('')

    const filteredOptions = computed(() => {
      if (!filterValue.value) return additionalSettingsFromOptions
      const q = new RegExp(escapeText(filterValue.value), 'i')

      const filtered = additionalSettingsFromOptions.filter((el: any) => !!el.subheader || q.test(el.label))
      return filtered.filter((el: any, i: number, arr: any[]) => {
        const nextItem = arr[i + 1]
        const nextNotSubheader = !nextItem?.subheader

        return !el.subheader || (el.subheader && nextItem && nextNotSubheader)
      })
    })

    const onSelect = (e: any) => {
      select.value = e
    }

    return {
      select,
      additionalSettingsFromOptions,
      filterValue,
      filteredOptions,
      onSelect,
    }
  },
})
